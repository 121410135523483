import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import RoutePage from './router';

// iOS10以后版本不接受meta标签，可以通过js监听手势控制来实现禁止页面缩放
document.addEventListener('gesturestart', function (event) {
  event.preventDefault()
});

// 安卓 长按禁止选中
document.oncontextmenu = function(e){
  return false;
};

ReactDOM.render(
  <React.StrictMode>
    <RoutePage />
  </React.StrictMode>,
  document.getElementById('root')
);
