import React, {Suspense, lazy} from "react";
import {HashRouter, Route, Switch, Redirect} from "react-router-dom";
const IndexPage = lazy(() => import("views/Index/Index"));
const ThemeIntroPage = lazy(() => import("views/ThemeIntroduce/Index"));
const ThemeLayoutPage = lazy(() => import("views/ThemeLayout/Index"));
const VirtualModelPage = lazy(() => import("views/VirtualModel/Index"));
const ExhibitorsPage = lazy(() => import("views/Exhibitors/Index"));
const QuestionnairePage = lazy(() => import("views/Questionnaire/Index"));
const CompanyIntroPage = lazy(() => import("views/CompanyIntro/Index"));
const PanoramaPage = lazy(() => import("views/Panorama/Index"));

function RoutePage() {
  return (
    <HashRouter>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/typeModel/index" exact component={IndexPage}/>
          <Route path="/typeModel/intro" exact component={ThemeIntroPage}/>
          <Route path="/typeModel/layout" exact component={ThemeLayoutPage} />
          <Route path="/typeModel/vModel" exact component={VirtualModelPage} />
          <Route path="/typeModel/exhibitors" exact component={ExhibitorsPage} />
          <Route path="/typeModel/questionnaire" exact component={QuestionnairePage} />
          <Route path="/typeModel/company" exact component={CompanyIntroPage} />

          <Route path="/typePanorama/index" exact component={IndexPage}/>
          <Route path="/typePanorama/intro" exact component={ThemeIntroPage}/>
          <Route path="/typePanorama/layout" exact component={ThemeLayoutPage} />
          <Route path="/typePanorama/exhibitors" exact component={ExhibitorsPage} />
          <Route path="/typePanorama/questionnaire" exact component={QuestionnairePage} />
          <Route path="/typePanorama/company" exact component={CompanyIntroPage} />
          <Route path="/typePanorama/panorama" exact component={PanoramaPage} />

          <Redirect from="/" to="/typePanorama/index" />
          <Route path="*" render={() => <div>page not found</div>} />
        </Switch>
      </Suspense>
    </HashRouter>
  );
}

export default RoutePage;